<template>
  <div class="faqs">
    <Header />
    <div class="banner">
      <div class="container">
        <div class="warpper">
          <div class="title">Frequently Asked Questions</div>
        </div>
      </div>
    </div>
    <div class="asq">
      <div class="container">
        <div class="asq-warpper">
          <div class="asq-info">
            <div
              :class="item.type ? 'asq-item click-item' : 'asq-item'"
              v-for="(item, index) in asq"
              :key="index"
            >
              <div class="question" @click="asqClick(index)">
                <img
                  src="~@/assets/images/qs.png"
                  alt=""
                  style="width: 24px; margin-right: 16px"
                />
                {{ item.question }}
              </div>
              <div class="answer" v-show="item.type">
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      asq: [
        {
          question: "How much I can get for my car?",
          type: false,
          answer:
            "We will contact you through the phone and discuss the condition of your car. After we reach an agreement for the price, an onsite inspection will be arranged. After the condition of the car is confirmed, we will pay you the agreed price. If the condition of the car is not as described, we will contact you for further discussion.",
        },
        {
          question: "How do I get the payment when I sell my car?",
          type: false,
          answer:
            "We will pay the agreed price directly into your bank account by Osko, which means you receive funds will happen in almost an instant, 24/7 - including weekends and public holidays. These near real-time payments automatically use Osko by BPAY.®",
        },
        {
          question: "What documents do I need to sell my car?",
          type: false,
          answer:
            "To make sure you are the actual owner of the vehicle, following documents are required when you sell the car: Photo ID (Driver’s License recommended), Registration papers. If you are selling the vehicle for anyone else, more documents are required: An authorization letter from the actual owner with signature, date and bank details; Photo ID of the registered owner (driver’s license recommended). ",
        },
        {
          question:
            "What should I do if my vehicle is still with outstanding finance?",
          type: false,
          answer:
            "You can still sell your car to us if the car is still having finance on it. We will help you with the payout process. To do this, we will need an up-to-date payout letter directly issued by your financial institution.",
        },
        {
          question: "When and how do you collect the car?",
          type: false,
          answer:
            "We will collect the car on the selected date chosen by you and us together. A toll truck or an authorized driver will come to collect the vehicle. Please remember to provide all sets of keys and all books (such as service logbook, user manual) to the driver.",
        },
        {
          question:
            "Do I need to provide Roadworthy Certificate or pink slip when I sell the car?",
          type: false,
          answer:
            "No, as we are a registered licensed motor dealer. No pink slip or RWC needed when you sell the car to us.",
        },
      ],
    };
  },
  methods: {
    // asq点击事件
    asqClick(index) {
      this.asq[index].type = !this.asq[index].type;
    },
  },
};
</script>

<style scoped lang="less">
.faqs {
  width: 100%;
  position: relative;
  padding-top: 120px;
  .banner {
    width: 100%;
    height: 400px;
    background: url("~@/assets/images/BgYellow_1.png");
    background-size: cover;
    .warpper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 40px;
        font-family: Poppins-Black, Poppins;
        font-weight: 900;
        color: #222222;
        line-height: 47px;
      }
    }
  }
  .asq {
    width: 100%;
    background: #fff;
    .asq-warpper {
      padding: 80px 0 80px 0;
      box-sizing: border-box;
      .asq-title {
        color: #212121;
        font-family: Poppins-Black;
        font-size: 40px;
        line-height: 47px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 24px;
      }
      .asq-info {
        width: 822px;
        margin: 0 auto;
        .asq-item {
          width: 100%;
          margin-bottom: 15px;
          .question {
            background: #f6f6f6;
            padding: 14px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-family: Poppins-SemiBold;
            color: rgba(0, 0, 0, 0.65);
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            cursor: pointer;
            transition: all 0.5s;
            .icon-plus-sign {
              margin-right: 16px;
            }
            .icon-minus-sign {
              margin-right: 16px;
            }
          }
          .answer {
            height: auto;
            padding: 10px 14px;
            box-sizing: border-box;
            color: rgba(0, 0, 0, 0.6);
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            font-family: Poppins-Regular;
            background: #fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            transition: all 1s;
          }
        }
        .click-item {
          .question {
            background: #f5c03e;
            color: rgba(57, 57, 57, 1);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .faqs {
    padding-top: 64px;
    .banner {
      height: 164px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
    .asq {
      .asq-warpper {
        padding: 20px 0;
        .asq-title {
          font-size: 24px;
          line-height: 28px;
        }
        .asq-info {
          width: 100%;
          .asq-item {
            .question {
              font-size: 12px;
              line-height: 14px;
            }
            .answer {
              padding: 10px 14px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 690px) and (max-width: 999px) {
  .faqs {
    padding-top: 64px;
    .banner {
      height: 164px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
    .asq {
      .asq-warpper {
        padding: 20px 0;
        .asq-title {
          font-size: 24px;
          line-height: 28px;
        }
        .asq-info {
          width: 100%;
          .asq-item {
            .question {
              font-size: 12px;
              line-height: 14px;
            }
            .answer {
              padding: 10px 14px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
